import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import avatar from '../images/me3.jpg';
import twitterImg from '../images/twitter.png';
import ghImg from '../images/github.svg';
import {
  AvatarContainer,
  CenteredSection,
  Container,
  NameContainer,
  SocialContainer,
  GHIcon,
  Img,
  TwitterIcon
} from '../styles';

const ghUrl = `https://github.com/bing107`;
const twitterUrl = `https://twitter.com/bingatweet`;

const IndexPage = () => {
  // const SPOITFY_CODE = window.btoa(`${process.env.SPOTIFY_CLIENT}:${process.env.SPOTIFY_SECRET}`);
  // console.log(process.env.S3_BUCKET_NAME, process.env.SPOTIFY_SECRET,SPOITFY_CODE)

  // useEffect(() => {
  //   fetch('https://accounts.spotify.com/api/token', {
  //     method: 'POST',
  //     // credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': `Basic ${SPOITFY_CODE}`
  //     },
  //     body: new URLSearchParams({grant_type: 'client_credentials'})
  //   })
  //   .then(response => {
  //     console.log(response);
  //   })
  //   .catch(console.log)
  // }, [])

  return (
    <Layout>
      <SEO lang="en" title="Home Page" />
      <Container>
        <AvatarContainer name="me">
          <Img src={avatar} alt="me" />
        </AvatarContainer>
        <NameContainer name="name">Hassan Syed</NameContainer>
        <CenteredSection name="about-me">
          Frontend Engineer @{' '}
          <a
            href="https://yiluhub.com/"
            target="_blank"
            style={{ color: 'springgreen' }}
            aria-label="Check out Yilu's home page"
            rel="noreferrer"
          >
            Yilu
          </a>
        </CenteredSection>
        <SocialContainer name="social">
          <a
            href={ghUrl}
            target="_blank"
            rel="noreferrer"
            aria-label="Go to my github profile"
          >
            <GHIcon src={ghImg} alt="github icon" />
          </a>
          <a
            href={twitterUrl}
            target="_blank"
            rel="noreferrer"
            aria-label="I occasionally tweet here"
          >
            <TwitterIcon src={twitterImg} alt="" />
          </a>
        </SocialContainer>
      </Container>
    </Layout>
  );
};

export default IndexPage;
