import styled from '@emotion/styled';

export const Container = styled('div')`
  display: grid;
  color: white;
  height: 100vh;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 200px 30px 50px auto;
  grid-gap: 25px;
  grid-template-areas:
    '. me .'
    '. name .'
    '. about-me .'
    '. social .';
  background-color: #002d37;
`;

type SectionType = {
  color?: string;
  name?: string;
};

export const Section = styled('section')<SectionType>`
  border: ${props => (props.color ? `1px solid ${props.color}` : 'none')};
  grid-area: ${props => props.name};
`;

export const CenteredSection = styled(Section)`
  justify-self: center;
`;

export const AvatarContainer = styled(CenteredSection)`
  margin-top: 20px;
`;

export const Img = styled('img')`
  margin: 0;
  clip-path: circle(44%);
  width: 10rem;
`;

export const NameContainer = styled(CenteredSection)`
  font-size: 2rem;
`;

export const SocialContainer = styled(Section)`
  display: flex;
  justify-content: space-evenly;
`;

export const GHIcon = styled('img')`
  width: 3.7rem;
  margin: 0;
`;

export const TwitterIcon = styled('img')`
  width: 4rem;
  margin: 0;
`;